import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { IoArrowForwardCircleOutline } from "react-icons/io5";

import { CallToAction } from "../components/site";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

import DroppahLogo from "../images/logos/Droppah/Droppah_Full_Black.svg";
import InvoxyLogo from "../images/logos/Invoxy/Invoxy_Full.svg";
import IRDLogo from "../images/logos/IRD/IRD_Full.svg";
import PowerBILogo from "../images/logos/Other/PowerBI_Full_Yellow.svg";
import XeroLogo from "../images/logos/Xero/Xero_Blue_Single.svg";
import MyHRLogo from "../images/logos/Other/MyHR_Logo.svg";
import DataphyllLogo from "../images/logos/Other/Dataphyll_Logo.png";
import AkahuLogo from "../images/logos/Other/Akahu_Logo.png";
import PicmiLogo from "../images/logos/Other/Picmi_Logo.png";
import LiveremLogo from "../images/logos/Other/LiveRem_logo.png";


const IntegrationsList = styled(Row)`
  --item-gap: 30px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > a {
    width: calc((100% / 3) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const IntegrationCard = styled.a`
  display: block;
  position: relative;
  text-decoration: none;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  background-color: ${(props) => props.theme.colours.white};
  border: solid 1px ${(props) => props.theme.colours.greyDarken};
  transition: all ${(props) => props.theme.transitions.med};

  &:hover {
    background-color: ${(props) => props.theme.colours.lightGrey};
  }

  &:active {
    background-color: ${(props) => props.theme.colours.greyDarken};
  }

  .logo-container {
    height: 80px;
    border-radius: ${(props) => props.theme.styles.borderRadius}
      ${(props) => props.theme.styles.borderRadius} 0 0;
    padding: 20px 30px 0 30px;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      img {
        max-width: 180px;
        max-height: 50px;
      }
    }

    .link-open {
      position: absolute;
      color: ${(props) => props.theme.colours.greyDarken};
      right: 30px;
      top: 30px;
      font-size: 2rem;
      transform: rotate(-45deg);
    }
  }

  .content-container {
    padding: 30px;

    h5 {
      color: #999;
      text-transform: uppercase;
    }

    h3 {
      color: ${(props) => props.theme.colours.dark};
      font-weight: 700;
      display: flex;
      align-items: center;

      .badge {
        margin-left: 7px;
      }
    }

    p {
      margin-bottom: 0;
    }

    .badge-container {
      img {
        max-height: 20px;
        max-width: 19px;
      }
    }
  }

  .learn-link {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
`;

const AddOns = (props) => {
  const integrations = [
    {
      logo: DroppahLogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "Staff Rostering",
      name: "Droppah",
      description:
        "Discover Droppah, staff rostering, time tracking & attendance software for your business. Simplify rostering & pay your staff right.",
      link: "/rostering",
    },
    {
      logo: InvoxyLogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "Contractor Management",
      name: "Karmly",
      description:
        "Share candidate data, timesheets, leave and expenses between Karmly and PayHero and get payroll right.",
      link: "/karmly",
    },
    {
      logo: XeroLogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "Accounting",
      name: "Xero",
      description:
        "Automatically send payroll transactions to Xero. Enjoy the convenience of advanced cost tracking and fast reconciliation with no data entry.",
      link: "/xero",
    },
    {
      logo: AkahuLogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "Payments",
      name: "Akahu",
      description:
        "Get payroll done & dusted in minutes by integrating PayHero with Akahu to pay your employees through a secure bank connection.",
      link: "/akahu",
    },
    {
      logo: DataphyllLogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "Time & Work Capture",
      name: "Dataphyll GROW",
      description:
        "Sync timesheets and piece rate data from Dataphyll’s fruit harvest management tools to PayHero.",
      link: "https://www.dataphyll.com/",
    },
    {
      logo: IRDLogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "Payroll & Tax",
      name: "Inland Revenue",
      description:
        "Automatically file your PAYE returns with Inland Revenue after each pay via a direct integration with IRD.",
      link: "/payday-filing",
    },
    {
      logo: MyHRLogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "HR",
      name: "MyHR",
      description:
        "Simplify employee onboarding, leave and payroll with live data sharing between MyHR and PayHero. MyHR is smart software backed by a team of on-call HR specialists.",
      link: "/myhr",
    },
    {
      logo: PicmiLogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "Employee Hiring and Onboarding",
      name: "PICMI",
      description:
        "Fast track hiring with PICMI. Sign up your workforce effortlessly, issue employee contracts and send payroll data to PayHero.",
      link: "https://www.picmi.io/",
    },
    {
      logo: PowerBILogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "Reporting",
      name: "Power BI",
      description:
        "Power BI is a suite of business analytics tools that deliver insights to your organization. Connect to hundreds of data sources, simplify data prep, and drive ad hoc analysis.",
      link: "https://powerbi.microsoft.com/en-us/",
    },
    {
      logo: LiveremLogo,
      full: false,
      coming_soon: false,
      new: false,
      category: "Salary Benchmarking",
      name: "LiveRem",
      description:
        "Get real time remuneration insights and market benchmarks when you integrate PayHero with LiveRem; a powerful tool to help you attract & retain talent.",
      link:
        "https://www.liverem.com/",
    },
  ];

  return (
    <Layout>
      <Seo
        title="Integrations & Add-Ons | PayHero"
        description="Integrate with other best-of-breed software applications so you can create a powerful ecosystem for managing employee time, payroll and accounts."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper>
          <Box css={{ maxWidth: "1000px" }} className="-textCenter -center">
            <h1>Super powerful integrations</h1>
            <h4>
              Integrate with other best-of-breed software applications so you
              can create a powerful ecosystem for managing employee time,
              payroll and accounts.
            </h4>
          </Box>
          <Row stackGap={30} isWrap justify="flex-start">
            {integrations.map((item, i) => {
              return (
                <Box size={100 / 3} offset={20}>
                  <IntegrationCard
                    href={item.link}
                    target={item.link.startsWith("/") ? "_self" : "_blank"}
                    rel={item.link.startsWith("/") ? "" : "noreferrer"}
                    key={i}
                  >
                    <div className="logo-container">
                      {item.link.startsWith("/") ? (
                        <Link to={item.link}>
                          <img
                            className={item.full && "-full"}
                            src={item.logo}
                            alt={item.name + " PayHero Integration"}
                          />
                        </Link>
                      ) : (
                        <a href={item.link} target="_blank" rel="noreferrer">
                          <img
                            className={item.full && "-full"}
                            src={item.logo}
                            alt={item.name + " PayHero Integration"}
                          />
                        </a>
                      )}
                      {!item.link.startsWith("/") && (
                        <IoArrowForwardCircleOutline className="link-open" />
                      )}
                    </div>
                    <Box stackGap={30} className="content-container">
                      <Box stackGap={7}>
                        <h5>{item.category}</h5>
                        <h3>
                          {item.name}
                          {item.coming_soon && (
                            <div className="badge -sm -grey">Coming Soon</div>
                          )}
                          {item.new && (
                            <div className="badge -sm -green">New</div>
                          )}
                        </h3>
                        <p>{item.description}</p>
                      </Box>
                    </Box>
                    {/* {item.link.startsWith("/") ? (
                    <Link className="link-floating learn-link" to={item.link}>
                      Find Out More
                    </Link>
                  ) : (
                    <a
                      className="link-floating learn-link"
                      href={item.link}
                      target="_blank"
                      
                    >
                      Find Out More <IoMdOpen css={{ top: "2px" }} />
                    </a>
                  )} */}
                  </IntegrationCard>
                </Box>
              );
            })}
          </Row>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default AddOns;
